import request from './request';

const platform = 'department'

export function login(data) {
  return request.post(`/${platform}/login`,data)
}


export function logout() {
  return request.get(`/${platform}/logout`)
}

/**
 * 修改密码
 */
export function editPassword(data) {
  return request.post(`/${platform}/editPassword`,data)
}

/**
 * 报事列表
 */
export function reportList(params){
  return request.get(`/${platform}/reportList`,{params})
}

/**
 * 带追踪记录的报事列表
 */
export function reportInfo(params){
  return request.get("/common/reportInfo",{params})
}


/**
 * 当前用户信息
 */
export function getUserInfo(params){
  return request.get(`/${platform}/userInfo`,{params})
}

/**
 * 报事统计
 */
export function reportStatistics(params){
  return request.get("/common/reportStatistics",{params})
}


/**
 * 导出报事列表
 */
export function exportListReports(params){
  return request.get("/common/exportListReports",{params});
}

/**
 *  获取监督岗列表
 */
 export function getSentryList(params){
  return request.get("/common/sentryList",{params})
}

/**
 * 获取监督岗人员列表
 */
 export function sentryUserList(params){
  return request.get("/common/sentryUserList",{params})
}

/**
 * 修改信息
 */
export function editInformation(data){
  return request.post("/common/editInformation",data)
}

/**
 * 修改下级
 */
export function editJuniorInformation(data){
  return request.post("/common/editJuniorInformation",data)
}

/**
 * 添加人员
 */
export function addInformation(data){
  return request.post("/common/addInformation",data)
}

/**
 * 获取当前全部下级
 */
export function getAllChildrenList(params){
  return request.get("/common/subordinateAllList",{params});
}

/***
 * 重置密码
 */
export function resetPassword(params){
  return request.get("/common/resetPassword",{params});
}

/***
 * 删除
 */
export function delInformation(params){
  return request.delete("/common/delInformation",{params});
}

/**
 * 获取后台监督岗统计
 */
export function sentryStatistics(params){
  return request.get("/common/sentryStatistics",{params});
}

/**
 *  根据上级获取下级
 */
export function subordinateList(params){
  return request.get("/common/subordinateList",{params});
}

/**
 * 接受报事
 */
export function disposeReport(data){
  return request.post(`/${platform}/disposeReport`,data);
}

/**
 *  添加报事追踪
 */
export function traceReport(data){
  return request.post(`/${platform}/traceReport`,data);
}

/**
 *  完成报事追踪
 */
export function overReport(data){
  return request.post(`/${platform}/overReport`,data);
}

/**
 * 导出报事列表
 */

export function exportReports(params){
  return request.get("/common/exportReports",{params});
}


/**
 * 导出人员
 */
export function exportUser(params){
  return request.get("/common/exportUser",{params});
}

/**
 * 导出监督岗
 */
 export function exportSentry(params){
  return request.get("/common/exportSentry",{params});
}


/**
 *  获取人员工种/政治面貌
 */
export function userConfig(params){
  return request.get("/common/userConfig",{params});
}


/**
 *  获取下级部门数量
 */
 export function juniorNum(params){
  return request.get("/common/juniorNum",{params});
}

