<template>
  <el-dropdown trigger="click">
    <el-button size="mini">
      系统设置<i class="el-icon-arrow-down el-icon--right"></i>
    </el-button>
    <el-dropdown-menu solt="dropdown">
      <el-dropdown-item>
        <router-link to="/changePwd"><i class="el-icon-s-tools"></i><span>修改密码</span></router-link>
      </el-dropdown-item>
      <el-dropdown-item>
        <span @click="loginOut"><i class="el-icon-switch-button"></i>用户退出</span>
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
import {createNamespacedHelpers} from 'vuex';
const {mapActions} = createNamespacedHelpers("user")
export default {
  name: 'UserDropDown',
  methods:{
    ...mapActions(['userLogout']),
    loginOut(){
      this.userLogout();
    }
  },
}
</script>

<style lang="scss" scoped>
button{
  background-color: #f80505;
  border: none;
  color: #FFFFFF;
  font-size: 14px;
  font-family: PingFang SC;
}
.el-dropdown-menu__item{
  padding: 0;
}
</style>
